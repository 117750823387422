// @flow
// $FlowFixMe
import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useMutation, useQuery} from '@apollo/client'
import marked from 'marked'
import {captureException} from '@sentry/browser'

import mailingListLegalText from '../../common/mailingListLegalText'
// import {hasOptedInToMailingList} from '../../common/consents'

import type {State} from '../../stateType'
import {actions as gateActions} from '../Gate/state'
import {actions as notificationActions} from '../Notifications/state'
import {actions as authActions} from '../Auth/state'

import type {SignupType} from '../../common/types'
import {brandIds} from '../../common/brands'
import {consents, genericItemTypes} from '../../common/constants'
import {validEmail} from '../../common/validation'
import getRequestHeaders from '../../common/getRequestHeaders'
import captureMutationErrors from '../../common/captureMutationErrors.js'

import Form from '../Form'
import Input from '../Input'
import Dropdown from '../Dropdown'
import Button from '../Button'
import Checkbox from '../Checkbox'
import FavouriteToggle from '../FavouriteToggle'
import CompleteTick from '../Svgs/CompleteTick'
import CityUpdatesLink from '../CityUpdatesLink'

import IS_FAVOURITED from '../FavouriteToggle/Query'
import CITIES_QUERY from '../Cities/Query'
// import GET_MAILING_PREFERENCES from '../OptIn/Query'
import UPDATE_MAILING_CONSENT from './Mutation.js'

import styles from './Signup.less'
import {analytics} from '../../common/analytics'
import Link from '../Link'

export default ({
  className = '',
  color,
  favouriteItemId,
  favouriteItemType,
  signup,
  signupSource,
  tags = [],
  uiLocation,
  enableUnfavourite,
  item,
}: {
  className?: string,
  color?: string,
  favouriteItemId?: number | string,
  favouriteItemType?: string,
  signup: SignupType,
  signupSource?: string,
  tags?: Array<string>,
  uiLocation?: string,
  enableUnfavourite?: boolean,
  item?: any,
}) => {
  const [emailAddress, setEmailAddress] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [loading, setLoading] = useState(false)
  const [complete, setComplete] = useState(false)
  const [activeConsentTags, setactiveConsentTags] = useState([])
  const [attemptedSignupWhilstLoggedOut, setAttemptedSignupWhilstLoggedOut] = useState(false)
  const [displayEmailError, setDisplayEmailError] = useState(false)
  const [missingRequiredConsents, setMissingRequiredConsents] = useState([])

  if (!signup) return null

  const {
    auth: {
      loading: authLoading,
      user: {email},
      isLoggedIn,
    },
    brand,
    geoLocation,
  } = useSelector((state: State) => state)
  const [city, setCity] = useState(geoLocation.city)

  const enableCitySelector = signup.enable_city_selector

  const dispatch = useDispatch()
  const openGate = () => dispatch(gateActions.openGate(signupSource, uiLocation, favouriteItemId, favouriteItemType))

  const isFourThree = brand.id === brandIds.fourthree
  const isCity = favouriteItemType === genericItemTypes.city
  const isShow = favouriteItemType === genericItemTypes.show

  const isProduct = favouriteItemType === genericItemTypes.product

  const tagsToPassToMutation = [
    ...signup.tags,
    ...tags.map(tag => tag),
    ...activeConsentTags.map(tag => tag),
    city,
  ]

  // check if the user has favourited a show
  const {data} = useQuery(IS_FAVOURITED, {
    variables: {
      item_type: favouriteItemType,
      item_id: favouriteItemId,
    },
    skip: isProduct || !favouriteItemId || !favouriteItemType,
    onError: () => console.error('There was an error fetching the favourite'),
  })

  // HOTFIX
  // Don't call mailing preferences when loading the Signup component
  // as querying consents is SLOW
  // https://www.notion.so/boilerroomtv/Charli-XCX-312d35068247450aaa3b98de9d97c60b?pvs=4
  // This temporarily means all users will be considered OPTED OUT and shown
  // opted out text + legal text
  //
  // // check if the user has opted into the mailing list
  // const {data: mailingData} = useQuery(GET_MAILING_PREFERENCES, {
  //   onError: () => console.error('There was an error checking the user opt in status'),
  // })

  // const hasOptedIn = mailingData && hasOptedInToMailingList(mailingData.profile)
  const hasOptedIn = false

  const [updateMailingConsent] = useMutation(UPDATE_MAILING_CONSENT, {
    variables: {
      consent: signup.opt_in ? {
        title: isFourThree ? consents.fourThreeMailingList : consents.boilerRoomMailingList,
        given: true,
      } : null,
      email: emailAddress,
      phone_number: phoneNumber,
      tags: tagsToPassToMutation || [],
      favourite_item_id: favouriteItemId,
      favourite_item_type: favouriteItemType,
      signup_id: signup.id,
    },
    context: {
      headers: getRequestHeaders(uiLocation, favouriteItemId, favouriteItemType),
    },
  })

  const updateEmail = (e: any) => {
    const email = e.target.value
    setEmailAddress(email)
    if (validEmail(email)) {
      setDisplayEmailError(false)
    }
  }

  const handleOnSubmit = e => {
    if (e) e.preventDefault()

    if (signup.requires_login && !isLoggedIn) {
      openGate()
      setAttemptedSignupWhilstLoggedOut(true)
      return
    }

    const isValidEmail = validEmail(emailAddress)

    const isLoggedOutWithInvalidEmail = !isLoggedIn && !isValidEmail

    if (isLoggedOutWithInvalidEmail) {
      setDisplayEmailError(true)
    }

    const requiredConsents = signup.consents && signup.consents.filter(consent => consent.required)
    const missingConsents = requiredConsents.filter(consent => !activeConsentTags.includes(consent.tag))
    const isMissingRequiredConsent = missingConsents.length > 0

    if (isMissingRequiredConsent) {
      setMissingRequiredConsents(missingConsents.map(consent => consent.tag))
    }

    if (isLoggedOutWithInvalidEmail || isMissingRequiredConsent) {
      return
    }

    setLoading(true)

    try {
      updateMailingConsent()
        .then(({data, errors}) => {
          // error handling - useMutation returns the error in the response
          if (errors && errors.length > 0) {
            captureMutationErrors(errors)
            setLoading(false)
            dispatch(notificationActions.setError('An error occured.'))
            return
          }

          if (item && isCity) {
            dispatch(
              notificationActions.setSuccess(
                <div className={styles.CitySuccessMessage}>
                  You are now subscribed to {item.city.name}.{' '}
                  <Link className={styles.CitiesLink} internalLink={'/account'}>
                    View your cities.
                  </Link>
                </div>,
              ),
            )
          }

          if (item && isShow && !signup.requiresLogin && !isLoggedIn) {
            dispatch(
              authActions.setUuid({uuid: data.update_mailing_consent}),
            )
          }
          setComplete(true)
          setLoading(false)
        })

      analytics.track('subscribe', {
        ui_location: uiLocation,
      })

      if (item && item.type === 'city') {
        analytics.track('favourite_city', {
          item_id: item.id,
          item_name: item.city.name,
        })
      }

      if (item && !!item.child_shows) {
        analytics.track('favourite_show', {
          item_id: item.id,
          item_slug: item.slug,
        })
      }

      analytics.track('Lead', {}, ['fb'])
    } catch (error) {
      captureException(error)
      dispatch(notificationActions.setError('An error occured.'))
    }
  }

  // Auto complete signup upon login if a user
  // has attempted whilst logged out
  useEffect(() => {
    if (isLoggedIn && attemptedSignupWhilstLoggedOut && !complete) {
      handleOnSubmit()
    }
  }, [isLoggedIn, attemptedSignupWhilstLoggedOut, complete])

  const addConsent = consentTag => setactiveConsentTags([...activeConsentTags, consentTag])
  const removeConsent = consentTag => setactiveConsentTags(activeConsentTags.filter(tag => tag !== consentTag))
  const isFavourited = data && data.is_favourited
  const shouldDisplayOptedInText = isFavourited || hasOptedIn

  // if the item is already favourited and enableUnfavourite is true, only <FavouriteToggle /> will be rendered
  if (isFavourited && enableUnfavourite) {
    return (
      <div className={styles.FavouriteToggleWrapper}>
        <FavouriteToggle item={item} disableHeart={true} />
      </div>
    )
  }

  // complete message for a Show Signup
  if (isShow && complete) {
    const city = item && item.city
    return (
      <div className={`${className} ${styles.ShowCompleteMessageWrapper}`}>
        <CompleteTick />

        <CompleteText completeText={signup.complete_text} email={email || emailAddress} />

        <CityUpdatesLink city={city} className={styles.UpdatesLink} arrowColor={'#fff'} />
      </div>
    )
  }

  if (complete) {
    // if enableUnfavourite is set to true and the user has clicked the initial Signup button - they will then unfavourite/favourite via <FavouriteToggle />
    if (enableUnfavourite) {
      return (
        <div className={styles.FavouriteToggleWrapper}>
          <FavouriteToggle item={item} disableHeart={true} />
        </div>
      )
    }
    // generic complete message
    return (
      <div className={`${className} ${styles.Wrapper}`}>
        <CompleteText completeText={signup.complete_text} email={email || emailAddress} />
      </div>
    )
  }

  return (
    <div className={`${className} ${styles.Wrapper}`}>
      <div
        className={styles.Text}
        dangerouslySetInnerHTML={{
          __html: shouldDisplayOptedInText ? marked(signup.text_for_opted_in_members) : marked(signup.text),
        }}
      />
      <Form
        className={styles.SignupForm}
        onSubmit={e => handleOnSubmit(e)}
        noValidate
      >
        {!signup.requires_login && !isLoggedIn &&
          <Input
            id='email'
            name='EMAIL'
            type='email'
            placeholder='email'
            value={emailAddress}
            label='Email'
            onChange={updateEmail}
            correct={validEmail(emailAddress)}
            required={signup.requires_login}
            highlightRequired={!validEmail(emailAddress)}
            displayEmailError={displayEmailError}
            className={displayEmailError && styles.InvalidEmailInput}
          />
        }

        {signup.phone_number_enabled &&
          <Input
            id='phone-number'
            type='tel'
            placeholder='phone number'
            label='Phone Number'
            value={phoneNumber}
            onChange={e => setPhoneNumber(e.target.value)}
            correct={phoneNumber.length > 9}
            required={signup.phone_number_required}
            className={styles.PhoneNumberInput}
          />
        }

        {enableCitySelector &&
          <CitySelector city={city} setCity={setCity} />
        }

        {signup.consents &&
          signup.consents.length > 0 &&
          signup.consents.map((consent, index) => (
            <Checkbox
              id={`signup-consent-${index}`}
              key={index}
              className={styles.SignupConsentCheckBox}
              onChange={e => (e.target.checked ? addConsent(consent.tag) : removeConsent(consent.tag))}
              required={consent.required}
              highlightRequired={consent.required && missingRequiredConsents.includes(consent.tag)}
              text={consent.label}
              checked={activeConsentTags.includes(consent.tag)}
              color={color}
            />
          ))
        }

        <Button
          type='submit'
          text={signup.button_text || 'SIGN UP'}
          className={styles.Button}
          loading={loading}
          disabled={authLoading}
        />

        {!hasOptedIn && signup.opt_in &&
          <p className={styles.SmallPrint} dangerouslySetInnerHTML={{__html: mailingListLegalText}} />
        }
      </Form>
    </div>
  )
}

const CompleteText = ({completeText, email}) => {
  if (completeText) {
    return <span dangerouslySetInnerHTML={{__html: marked(completeText)}} />
  }

  return <p>You are signed up with {email}</p>
}

const CitySelector = ({city, setCity}) => {
  const {data, loading} = useQuery(CITIES_QUERY)

  if (loading || !data) return null

  // Prepend an empty string so a user can select no city
  // or if the geolocation does not find a match
  const cities = ['', ...data.cities.map(city => city.name).sort()]

  return (
    <Dropdown
      onChange={e => setCity(e.target.value)}
      value={city}
      label='City'
      options={cities}
    />
  )
}
